import styled from '@emotion/styled'
import { Navbar } from '../components/navbar'
import { Hero } from '../components/home/hero'

export const HomeBackground = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fcfcfc;
`

export const Home = () => (
    <div>
        <HomeBackground id='background'>
            <Navbar />
            <Hero />
        </HomeBackground>
    </div>
)
