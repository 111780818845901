import React from 'react'
import Main from './pages/Main'

function App() {
    return (
        <div>
            <Main />
        </div>
    )
}

export default App
