import styled from '@emotion/styled'
import heroImage from '../../assets/images/hero-image.png'

const HeroWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 128px;
    gap: 128px;
    width: 100%;
    height: 100%;
    background: #d5dbf1;
`
const StyledImage = styled.img`
    width: 260px;
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 707px;
    height: 446px;
`
const HeroH1 = styled.div`
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 78px;
    letter-spacing: 0.1em;
    color: #3e404e;
`

const RegularText = styled.p`
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 45px;
    text-align: justify;
    letter-spacing: 0.05em;
    color: #000000;
`

export const Hero = () => (
    <HeroWrapper id='Hero wrapper'>
        <TextWrapper>
            <HeroH1>Simple Interactive Designs</HeroH1>
            <RegularText>
                With the exponential growth of the digital world, INSO Systems is dedicated to provide innovative and clean website designs to local businesses in British Columbia.
            </RegularText>
        </TextWrapper>
        <StyledImage src={heroImage} />
    </HeroWrapper>
)
