import styled from '@emotion/styled'
import insoLogo from '../assets/images/inso_logo4.png'

const NavWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 64px;
    gap: 10px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
`
const StyledImage = styled.img`
    width: 100px;
`

export const Navbar = () => (
    <NavWrapper>
        <StyledImage src={insoLogo} />
    </NavWrapper>
)
